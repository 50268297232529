export const headers = [
  {
    name: "Ciclo",
    id: "ciclo",
    col: 0,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Campus",
    id: "campus",
    col: 1,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Salón",
    id: "salon",
    col: 2,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "Clave Profesor",
    id: "clave_profesor",
    col: 3,
    fn: null,
    width: "18%",
    align: "center",
  },
  {
    name: "Nombre Profesor",
    id: "nombre_profesor",
    col: 4,
    fn: null,
    width: "19%",
    align: "center",
  },
  {
    name: "Grupo",
    id: "grupo",
    col: 5,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Clave Materia",
    id: "clave_materia",
    col: 6,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Nombre Materia",
    id: "nombre_materia",
    col: 7,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Tipo Clase",
    id: "tipo_clase",
    col: 8,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Hora Llegada",
    id: "hora_llegada",
    col: 9,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Nombre Checador",
    id: "nombre_checador",
    col: 10,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "1era. Revisión",
    id: "1era_evision",
    col: 11,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "2nda. Revisión",
    id: "2nda_revision",
    col: 12,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Entrada AI",
    id: "entrada_AI",
    col: 13,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Salida Anticipada",
    id: "salida_anticipada",
    col: 14,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "# Alumnos Programados",
    id: "total_alumnos_pogramados",
    col: 15,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "# Alumnos de Asistencia",
    id: "total_alumnos_asistencia",
    col: 16,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Hora Mínima",
    id: "hora_minima",
    col: 17,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Notas",
    id: "notas",
    col: 18,
    fn: null,
    width: "15%",
    align: "center",
  },
];

export const headersPDF = [
  {
    name: "Salón",
    id: "salon",
    col: 0,
    fn: null,
    width: "10%",
    align: "center",
  },
  {
    name: "Clave Profesor",
    id: "clave_profesor",
    col: 1,
    fn: null,
    width: "10%",
    align: "center",
  },
  {
    name: "Nombre Profesor",
    id: "nombre_profesor",
    col: 2,
    fn: null,
    width: "20%",
    align: "center",
  },
  {
    name: "Grupo",
    id: "grupo",
    col: 3,
    fn: null,
    width: "10%",
    align: "center",
  },
  {
    name: "Clave Materia",
    id: "clave_materia",
    col: 4,
    fn: null,
    width: "10%",
    align: "center",
  },
  {
    name: "Tipo Clase",
    id: "tipo_clase",
    col: 6,
    fn: null,
    width: "12%",
    align: "center",
  },
  {
    name: "Hora Llegada",
    id: "hora_llegada",
    col: 7,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Nombre Checador",
    id: "nombre_checador",
    col: 8,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "1era. Revisión",
    id: "1era_evision",
    col: 9,
    fn: null,
    width: "10%",
    align: "center",
  },
  {
    name: "2nda. Revisión",
    id: "2nda_revision",
    col: 10,
    fn: null,
    width: "10%",
    align: "center",
  },
  {
    name: "Entrada AI",
    id: "entrada_AI",
    col: 11,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "Salida Anticipada",
    id: "salida_anticipada",
    col: 12,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "# Alumnos Programados",
    id: "total_alumnos_pogramados",
    col: 13,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "# Alumnos de Asistencia",
    id: "total_alumnos_asistencia",
    col: 14,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "Hora Mínima",
    id: "hora_minima",
    col: 15,
    fn: null,
    width: "12%",
    align: "center",
  },
  {
    name: "Notas",
    id: "notas",
    col: 16,
    fn: null,
    width: "15%",
    align: "center",
  },
];
