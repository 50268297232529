import { Grid, Typography } from "@material-ui/core";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import { getRequest } from "../../Utils/Funciones/requester";
import { generateCSV } from "../../Utils/generateCSV/generateCSV";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { useSelector } from "react-redux";
import { CustomSpinner } from "../../Utils/Loader";
import SearchInput from "../../Utils/Visuales/SearchInput";
import { headers, headersPDF } from "../../Utils/data/prefecturaReporteHeaders";

function Prefectura() {
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const { enqueueSnackbar } = useSnackbar();
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueCampus, setValueCampus] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [cicloValue, setCicloValue] = useState("");
  const [fechaInicioValue, setFechaInicioValue] = useState("");
  const [fechaFinValue, setFechaFinValue] = useState("");
  const [tipoClaseReport, setTipoClaseReport] = useState([]);
  const [horaValue, setHoraValue] = useState("");
  const [minutosValue, setMinutosValue] = useState("");

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  const GetDataTable = async () => {
    let valido = true;
    if (fechaInicioValue === "") {
      enqueueSnackbar("Debe ingresar Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaFinValue === "") {
      enqueueSnackbar("Debe ingresar Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus === "" || valueCampus === null) {
      enqueueSnackbar("Debe ingresar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      setShowTable(true);
      let url = `/reportes/prefectura?fecha_inicio=${fechaInicioValue}&fecha_fin=${fechaFinValue}&campus=${valueCampus.value}`;
      if (cicloValue !== "") url += `&ciclo=${cicloValue}`;
      if (valueDireccion !== "") url += `&direccion=${valueDireccion.value}`;
      if (horaValue !== "" && minutosValue !== "")
        url += `&hora=${
          horaValue < 10 ? "0" + parseInt(horaValue).toString() : horaValue
        }:${
          minutosValue < 10
            ? "0" + parseInt(minutosValue).toString()
            : minutosValue
        }`;
      const response = await getRequest(url);
      const datos = [];
      if (response.s === "OK") {
        if (response.d.length === 0 || response.d === "") {
          setData(datos);
          setDataOriginal(datos);
          enqueueSnackbar("No se han encontrado datos", {
            variant: "info",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setTipoClaseReport(response.d[8]["tipo_clase"]);
          for (let i = 0; i < response.d[0]["ciclo"].length; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["salon"][i],
              response.d[3]["clave_profesor"][i],
              response.d[4]["nombre_profesor"][i],
              response.d[5]["grupo"][i],
              response.d[6]["clave_materia"][i],
              response.d[7]["nombre_materia"][i],
              response.d[8]["tipo_clase"][i],
              response.d[9]["hora_llegada"][i],
              response.d[18]["nombre_checador"][i],
              response.d[10]["1era_revision"][i],
              response.d[11]["2nda_revision"][i],
              response.d[12]["entrada_AI"][i],
              response.d[13]["salida_anticipada"][i],
              response.d[16]["total_alumnos_programados"][i],
              response.d[14]["total_alumnos_asistencia"][i],
              response.d[15]["hora_minima"][i],
              response.d[17]["notas"][i],
            ]);
          }
          setData(datos);
          setDataOriginal(datos);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setLoading(false);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setData(datos);
        setDataOriginal(datos);
        setLoading(false);
      }
    }
  };

  const handleGeneratedReport = async () => {
    const date = moment().format("DD/MM/YYYY");
    const dataTable = data.map((item) =>
      Object.fromEntries(item.map((_, i) => [headers[i].name, item[i]]))
    );
    await generateCSV({
      data: dataTable,
      fileName: `Reportes_prefectura_${date}.xlsx`,
    });
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.map((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const DownloadPDF = async () => {
    if (data.length >= 300) {
      enqueueSnackbar("Supera limites de Páginas.", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    setReq(true);
    const dataPDF = [];
    let recorrido = 0;
    //PROBLEMAS AL TENER MAS DE 500 FILAS EN EL PDF SE TRABA TODA LA PAGINA.
    if (data.length > 400) {
      recorrido = 300;
    } else {
      recorrido = data.length;
    }
    for (let i = 0; i < recorrido; i++) {
      dataPDF.push({
        salon: data[i][2],
        clave_profesor: data[i][3],
        nombre_profesor: data[i][4],
        grupo: data[i][5],
        clave_materia: data[i][6],
        nombre_materia: data[i][7],
        tipo_clase: data[i][8],
        hora_llegada: data[i][9],
        nombre_checador: data[i][10],
        primera_revision: data[i][11],
        segunda_revision: data[i][12],
        entrada_AI: data[i][13],
        salida_anticipada: data[i][14],
        total_alumnos_pogramados: data[i][15],
        total_alumnos_asistencia: data[i][16],
        hora_minima: data[i][17],
        notas: data[i][18],
      });
    }
    const date = moment().format("DD/MM/YYYY");
    const hour = moment().format("HH:mm:ss");
    var customTipoClase = tipoClaseReport.filter(onlyUnique);
    const doc = (
      <DocumentPDFTable
        metadata={headersPDF ?? []}
        data={dataPDF ?? []}
        title={`Reporte Prefectura`}
        campus={valueCampus.label ?? ""}
        startDate={fechaInicioValue ?? ""}
        endDate={fechaFinValue ?? ""}
        ciclo={cicloValue ?? ""}
        direccion={valueDireccion.label ?? ""}
        hora={horaValue ?? ""}
        minutos={minutosValue ?? ""}
        tipo_clase={customTipoClase.join(", ") ?? "-"}
        orientation="portrait"
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Prefectura_${date}_${hour}.pdf`);
    setReq(false);
  };

  function downloadCsv() {
    handleGeneratedReport().finally(() => {});
  }

  function handleHoraValue(value) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      setHoraValue(value);
    }
  }

  function handleMinutosValue(value) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      setMinutosValue(value);
    }
  }
  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Prefectura">
        <Grid
          container
          className="nuevoUsuarioForm"
          style={{ position: "relative", zIndex: 9 }}
        >
          <Grid container item xs={12} sm={9}>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                container
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <Grid style={{ textAlign: "end", width: "125px" }}>
                  <label>Campus(*):</label>
                </Grid>
                <Grid item style={{ width: 200 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    isClearable={true}
                    value={valueCampus}
                    options={campusValores}
                    onChange={setValueCampus}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <Grid style={{ textAlign: "end", width: "125px" }}>
                  <label>Fecha Inicio(*):</label>
                </Grid>
                <Grid item style={{ width: 200 }}>
                  <input
                    className="filter-input"
                    type="date"
                    value={fechaInicioValue}
                    onChange={(e) => setFechaInicioValue(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <Grid style={{ textAlign: "end", width: "90px" }}>
                  <label>Fecha Fin(*):</label>
                </Grid>
                <Grid item style={{ width: 200 }}>
                  <input
                    className="filter-input"
                    type="date"
                    value={fechaFinValue}
                    onChange={(e) => setFechaFinValue(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: 8 }}
            >
              <Grid
                container
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: 325,
                  marginBottom: 10,
                }}
              >
                <Grid style={{ textAlign: "end", width: "125px" }}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid item xs={16} style={{ width: 200 }}>
                  <input
                    className="filter-input input-ciclo"
                    placeholder="Indique el ciclo..."
                    value={cicloValue}
                    onChange={(e) => setCicloValue(e.target.value)}
                    width={"100%"}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: 425,
                  marginBottom: 10,
                }}
              >
                <Grid style={{ textAlign: "end", width: "125px" }}>
                  <label>Direccion:</label>
                </Grid>
                <Grid item style={{ width: 200 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    isClearable={true}
                    value={valueDireccion}
                    options={optionsDireccion}
                    onChange={setValueDireccion}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: 425,
                  marginBottom: 10,
                }}
              >
                <Grid style={{ textAlign: "end", width: "90px" }}>
                  <label>Hora:</label>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    width: 200,
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    className="filter-input"
                    type="number"
                    min="0"
                    max="23"
                    style={{ width: 145 }}
                    value={horaValue}
                    onChange={(e) => handleHoraValue(e.target.value)}
                  />
                  <input
                    className="filter-input"
                    style={{ width: 145 }}
                    type="number"
                    min="0"
                    max="59"
                    value={minutosValue}
                    onChange={(e) => handleMinutosValue(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} spacing={2}>
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={() => GetDataTable()}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={downloadCsv}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(70%)" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
}

export default Prefectura;
